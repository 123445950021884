.App {
  text-align: center;

}

.patternBg{
  width: 35vw;
  height: 50vh;


  z-index: 0;
  mix-blend-mode: exclusion;
  opacity: 0.07;
  z-index: 1;
  pointer-events: none;
  position: fixed;



}

.patternBg img{
  width: 100%;
  object-fit: cover;

}

.patternBg.top{

  top: 0;
  right: 0;
  margin-right: -15vw;
  z-index: 1;
  pointer-events: none;


}

.patternBg.bottom{

  bottom: -30vw;
  left: -10vw;
  z-index: 1;
  pointer-events: none;


  transform: scaleY(-1) scaleX(-1);
}



.project-header h1{

    position: relative;
    font-size: clamp(60px, 14vw, 40rem);
    font-weight: 300;
    line-height: 1;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    letter-spacing: 2vw;




}

h1, h2, h3, h4, h5, span{
  font-family: 'Bebas Neue', sans-serif;


}



@media screen and (max-width: 850px){
  .block{
    width: 60vw !important;
  }
}
