
/* Footer Styles */

.footer--hero{
  position: absolute;
  height: auto;
  width: 100%;
  bottom: 0;
  z-index: 10;
  padding: 0px;
  margin: 0px;
}

.footer--wrapper{
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0% 5%;
  pointer-events: auto;

  z-index: 2;
  left: 50%;
  transform: translateX(-50%);

}

.footer--socials{
  position: absolute;

  align-items: center;
  justify-content: center;
  gap: 7vw;
  transition: opacity 300ms ease-in-out;
  z-index: 1000;


}


.footer--socials svg, .footer--socials path{
  cursor: pointer;
  transition: 200ms ease-in-out;
  font-size: 14px;

}


.footer--socials svg:hover, .footer--socials path:hover{
    opacity: 0.6;
    transform: translateY(0.075rem);

}


.footer--portfolio{
  position: absolute;

  padding: 0 5%;
  grid-template-columns: 1fr 20% 1fr;
  width: 100%;
  transition: opacity 300ms ease-in-out;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

}



.footer--roles{
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 20px;
  padding: 0px;
}

.footer--roles p{
  font-size: 13px;
}


.footer--link-view, .footer--link-web{
  font-size: 12px;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  margin-bottom: 25px;
  font-size: 14px;
  text-decoration: none;
  pointer-events: auto;
}


.footer--dates{
  display: flex;
  align-items: flex-start;
  justify-content: center;

}

.footer--dates p{
  text-align: center;
  line-height: 0.95;
  font-size: 13px;
}



@media screen and (max-width: 800px){

  .footer--wrapper{
    width: 100%;
    padding-top: 0px;

    position: absolute;

  }

  .footer--socials{

    left: 50%;
    bottom: 0;
    transform: translateX(-50%);

    width: 100%;
    height: 60px;



  }

  .footer--portfolio{
    grid-template-columns: auto;
    grid-template-rows: repeat(3, 1fr);
    grid-gap: 10px;
    line-height: 0;
    height: 150px;
    margin-top: -50px;
    padding: 0;




  }


  .footer--link{
    grid-row: -1;
    margin: 0px;
    height: 50px;


    line-height: 1;

  }


  .footer--link-view, .footer--link-web{
    height: 125%;
    margin-top: 2px;
    background: rgba(1,1,1,0.1);
    backdrop-filter: blur(1.85px);
    z-index: 1000;





  }

  .footer--link-web{

  }

  .footer--dates{
    grid-row: 1;
    margin: 0px;
    padding: 0px;

    height: 10px;
    display: none;

  }


  .footer--roles{
    grid-row: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0;
    margin: 0px;
    padding: 0px;
    line-height: 0.8;
    min-height: 60px;
    display: none;



  }



}


@media screen and (min-width: 650px){
  .footer-wrapper{
    z-index: 1;
  }
}
