

/* Portfolio Styles */



.portfolio--container{
  margin: 0;
  width: 100%;
  padding: 10px;
  padding-bottom: 25vh;
  text-align: left;


}

.section{
  margin: 2vh auto;
  width: calc(100% - 110px);
  min-height: 85vh;
  max-width: 900px;
  display: flex;
  flex-direction: column;
  justify-content: center;


}


.section > h1, h2, h3{
  font-size: 40px;
  margin: 15px 0px;

}



.section .title-description {
  width: 100%;
  display: flex;
  justify-content: space-between;


}

.title-description p, .logo-section p{
  font-weight: 500;
  text-transform: uppercase;
  margin: 25px 0px;


}



.section-description p{

  margin: 50px 0px !important;


}


.grid-section{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 25px;
}


.video-section{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;


  width: 90%;
  max-width: 80vh;
  margin: 15vh auto;


}

.video-section div{


  padding: 0px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;



}
.video-section video{


  width: 100%;




}




.player-row{
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 2%;
  width: 100%;
  margin: 2% auto;
}


.player-row:first-child{
  margin-left: -5%;
}

.player-row:last-child{
  margin-right: -5%;
}


.player-row img{
  width: 100%;
}


.section--design-system{
  display: grid;
  grid-template-columns: 55% 35%;
  grid-template-rows: auto 1fr;
  column-gap: 10%;
  padding: 25px 0px;
  margin: 25vh auto 5vh auto;



}

.section--design-system .colors{
  margin: 0px auto;
  width: 100%;


}

.section--design-system .fonts{
  margin: 0px auto;
  width: 100%;
}


.font-section{
  display: flex;
  flex-direction: column;
  gap: 35px;
  width: 90%;
}




.section--design-system h2{
  grid-row: 1;
  grid-column: 1 / -1;



}

.section--design-system .colors > p,
.section--design-system .fonts > p{
  font-weight: 500;
  text-transform: uppercase;
  margin: 25px 0px;


}

.section--design-system .squares{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2vw;
  margin-top: 20px;
}

.section--design-system .colour{

  width: 100%;
  display: flex;
  gap: 10px;
  align-items: center;

}

.colour .square{
  width: 50px;
  height: 50px;
  box-shadow: 0px 10px 10px rgba(1,1,1,0.3);

}

.section--design-system img{
  width: 100%;
}



.logo-col-2{
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;

  text-align: center;
}



.logo-col-2 .logo-container:first-child{
  background: white;
  padding: 50px;
}

.logo-col-2 .logo-container:last-child{
  background: #3A3A3C;
  padding: 50px;
}



.logo-col-3{
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  align-items: center;
  grid-gap: 5%;
  text-align: center;
}


.logo-sketch{
  width: 100%;
  display: grid;
  grid-template-columns: auto;
  align-items: center;
  grid-gap: 5%;
  text-align: center;

}

.logo-sketch img{
  max-width: 600px;
}

.logo-sketch-3{
  grid-template-columns: repeat(3, auto);
}

.logo-section img{
  width: 100%;
  margin: auto;
}

.logo-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding: 10px;
}


@media screen and (max-width: 800px){

  .section {
    min-height: 50vh;

  }



  .section--design-system{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, auto);
    column-gap: 25px;
    padding: 25px 0px;



  }

  .player-row{
    grid-template-columns: repeat(2, 1fr);

  }

  .player-row:first-child, .player-row:last-child{
    margin-left: 0;
    margin-right: 0;
  }

  .logo-col-2, .logo-col-3, .logo-sketch-3{
    grid-template-columns: repeat(1, 1fr);
    margin: 50px auto;
  }



  .image-section{
    width: 100%;


  }

  .image-section img{
    width: 100%;

  }
}
