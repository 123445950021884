
.heroSection{
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  inset: 0 0 0 0;
  z-index: 0;
  transition: 500ms ease-in-out;



}

.heroContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 500ms ease-in-out;


}


.heroBig{
  color: white;
  font-family: 'Kolker Brush', cursive;
  font-size: clamp(100px, 14vw, 100rem);
  transform: rotate(-6deg);
  text-transform: uppercase;
  position: absolute;
  letter-spacing: -6px;
  mix-blend-mode: exclusion;
  user-select: none;
  margin-left: -2vw;
}

.heroSmall{
  position: absolute;
  margin-top: -12.5vw;
  left: 25%;
  font-size: clamp(20px, 2vw, 10rem);
  text-transform: uppercase;
  display: flex;
  padding-top: 0px;
  mix-blend-mode: exclusion;
  line-height: 7vw;
  user-select: none;

}

.heroSmall span{
  margin: 0px 2px;
  font-weight: 200;
}

.heroSmall span:nth-child(1){
  align-self: flex-start;
  margin-top: -2vw;
  font-family: 'Poppins';

}

.heroSmall span:nth-child(2){
  color: white;
  font-size: clamp(70px, 8vw, 30rem);
  font-family: 'Bebas Neue';

}

.heroSmall span:nth-child(3){
  margin-top: 1.5vw;
  font-family: 'Poppins';
}

@media screen and (max-width: 625px){



  .heroContainer, .heroSmall, .heroBig{
    position: relative;
    padding: 0;
    margin: 0;
    inset: 0 0 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    line-height: normal;


  }


  .heroContainer{
    margin-top: -10vh;
  }



  .heroSmall span:nth-child(1), .heroSmall span:nth-child(3) {
    align-self: center;
    padding: 0;
    margin: 0;
    letter-spacing: 1px;
    line-height: 5px;
  }


  .heroBig{
    margin-left: -3vw;
  }

  .heroSmall span:nth-child(2){
    color: white;
    font-size: 80px;
    letter-spacing: 2px;
    font-family: 'Bebas Neue';
    line-height: normal;
    margin: 0px;

  }







}
