
/* Home Styles  */



body {

  overscroll-behavior-y: none;
  position: relative;
  overflow-x: hidden;


}


.homeBtn{
  position: fixed;
  width: 55px;
  height: 55px;
  cursor: pointer;
  top: 0;
  left: 0;
  pointer-events: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

}





.project-hero{

  width: 100vw;
  height: 100vh;

  inset: 0 0 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 55px;

}




.project-hero--wrapper{
  width: 100%;
  height: 100%;
  z-index: 0;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
}


.project-hero--wrapper img{
  grid-column: 1;
  margin: auto;
  width: 44.5vw;
  height: 22.5vw;
  object-fit: cover;
  object-position: center;
}


.project-header{
  position: absolute;

  inset: 0 0 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

}

.headerWrapper{
  position: absolute;
  display: flex;
  align-items: center;
  width: 100%;
  height: 50%;
  padding: 55px;


}

.headerWrapper:first-of-type{

  align-items: flex-end;
  top: 0;

}

.headerWrapper:last-of-type{

  bottom: 0;

  align-items: flex-start;


}


.headerWrapper h1{
  line-height: 1;
  word-break: break-all;
  height: auto;

}


.image1, .image2{
  position: absolute;

  inset: 0 0 0 0;

  display: flex;
  justify-content: center;
  align-items: center;

}



.mobile-data{
  display: none;
}




@media screen and (max-width: 800px){


  .mobile-data{
    display: flex;
    flex-direction: column;
    grid-row: 3 / 3;
    font-size: 11px;
    line-height: 1.1;
    gap: 5px;
    justify-content: flex-start;


  }

  .mobile-data div:nth-child(2){

    display: flex;
    flex-direction: column;
    gap: 5px;
  }



  .project-hero--wrapper{

    grid-template-rows: 250px 1fr 1fr;
    position: relative;
    width: 100vw;
    padding: 0;

    height: 100% !important;

  }
  .image1, .image2{
    height: 100%;
    width: 100%;
    grid-row: 1 /2;

  }

  .image1 img, .image2 img {
    width: 100%;
    height: 100%;

  }

  .project-hero--wrapper .backTxt,
  .project-hero--wrapper  .frontTxt{

    grid-row: 2 / 3 !important;
    height: 150px;

    width: 100%;




  }

  .headerWrapper{
    padding: 10px;
    display: flex;
    align-items: center !important;


  }




}










.wrapper--home{
  position: fixed;
  height: 100vh;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100vw;
  overflow: hidden;
  pointer-events: none;
  z-index: 10;


  justify-content: center;
  align-items: center;

}

.scroll{
  padding-top: 75vh;
  padding-bottom: 50vh;
  width: 100%;
  margin: 0 auto;
  pointer-events: none;

}



.scroll .block{
  position: relative;
  height: 100px;
  width: 35%;
  margin: 10px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transform-origin: bottom;
  cursor: pointer;
  transition:
  height 900ms ease-in-out,
  width 900ms ease-in-out;
  pointer-events: auto;
}



.block img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: grayscale(100%);
  pointer-events: none;
}

.block:hover > img{
  filter: grayscale(0%) !important;
}





.zoom{
  index: 0 !important;

}

.zoom:not(.active){
  z-index: -1;
  pointer-events: none !important;
  transition-delay: 800ms;
}

.zoom:not(.active) img{
  filter: grayscale(100%) !important;
  opacity: 0.00;
}


.zoom .active{
  pointer-events: auto !important;
  z-index: -1;
  align-self: center;
  margin: 20vh auto !important;
  position: relative;
  display: flex;
  opacity: 0;

}




.frontTxt h1, .backTxt h1{
  -webkit-text-stroke-width: 2px;
  mix-blend-mode: none;
  word-break: break-word;



}


@media screen and (max-width: 800px){

  .frontTxt h1, .backTxt h1{
    line-height: 1;

  }
}


@media screen and (max-width: 650px){

  /* .heroSection{
    position: relative;
  }

  .wrapper--home{
    position: relative;
    overflow: none;
  }

  .scroll{
    padding-top: 0px;
  } */



}
