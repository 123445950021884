.slick-slider{


}

.slick-list{
  height: 100%;
  width: 100%;
  margin: auto;
  align-self: center;




}


.slick-track{
  height: 100%;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;


}

.slick-slide{
  display: grid;
  grid-template-rows: 250px 1fr 1fr;
  height: 100%!important;
  padding: 0;
  position: relative;
  width: 100vw;


}



.slick-disabled {
  visibility: hidden !important;
}


.slick-prev::before, .slick-next::before{
  font-family: 'Poppins';

}


.slick-prev, .slick-next{
    height: calc(100vh);
    width: 55px;
    z-index: 1000;
    pointer-events: auto !important;
    



}

.slick-prev, .slick-prev:hover, .slick-prev:focus{


}

.slick-next,  .slick-next:hover, .slick-next:focus{


}


.slick-prev{
  left: 0% !important;
}


.slick-next{
  left: 100% !important;
  margin-left: -55px;
}



.slick-dots{
  margin-bottom: 75px !important;
  margin-left: -30px;
  display: none !important;

}

.slick-dots li{
  margin: 0;
  width: 10px;
  height: 10px;

}

.slick-dots li button:before{
  color: white;
}

.slick-dots li:nth-child(1) button:before {
  display: none;

}


.slick-dots li.slick-active button:before{
  color: white !important;
}
