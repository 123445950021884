
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Kolker+Brush&family=Poppins:wght@100;200;300;400;600&display=swap');




::-webkit-scrollbar {
    width: 0;
    background: transparent;
    display: none;
    scrollbar-width: none;
    -ms-overflow-style: none;
}
/* Optional: show position indicator in red */


* {
  box-sizing: border-box;
  color: white;
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  scrollbar-width: none;
  -ms-overflow-style: none;

}

html{
  background: #0E0E11;

}



body.loading{
  overflow: hidden;
  height: 100vh;
}
